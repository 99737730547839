import './bootstrap'
import '../css/app.css'

import { createApp, h, DefineComponent } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { ZiggyVue } from 'ziggy-js'
import VueLazyload from 'vue3-lazyload'
import Hotjar from '@hotjar/browser'
import { gtag, initGtag } from './Composables/googleAnalyticsEvents'
import { sendErrorToBackend } from '@/Composables/use-backend-log'

createInertiaApp({
    title: (title) => `${ title }`,
    resolve: (name) => resolvePageComponent(`./Pages/${ name }.vue`, import.meta.glob<DefineComponent>('./Pages/**/*.vue')),
    setup ({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })

        app.use(plugin)
            .use(ZiggyVue)
            .use(VueLazyload, {})

        // gtag start
        if (import.meta.env.VITE_GTM_CONTAINER_ID && import.meta.env.VITE_MEASUREMENT_ID) {
            //Initialize gtag function
            gtag()

            // Initialize gtag script with default consent state
            initGtag()
        }

        // Initialize Hotjar
        if (import.meta.env.VITE_HOTJAR_SITE_ID) {
            Hotjar.init(import.meta.env.VITE_HOTJAR_SITE_ID, import.meta.env.VITE_HOTJAR_VERSION, {
                debug: import.meta.env.VITE_HOTJAR_DEBUG_MODE === 'true'
            })
        }

        // Global error handler for Vue
        app.config.errorHandler = (error, instance, info): void => {
            console.error('Vue Error:', error)
            console.error('Error Info:', info)
            console.error('Component:', instance)

            sendErrorToBackend({
                error: error instanceof Error ? error.toString() : String(error),
                info,
                component: instance
            })
        }

        // Global warning handler for Vue
        app.config.warnHandler = (msg, instance, trace): void => {
            console.warn('Vue Warning:', msg)
            console.warn('Warning Trace:', trace)
            console.warn('Component:', instance)

            sendErrorToBackend({
                error: msg,
                trace,
                component: instance
            })
        }

        app.mount(el)
    },
    progress: {
        delay: 1,
        color: '#ff5852',
        showSpinner: true
    }
})