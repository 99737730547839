import axios from 'axios'
import { usePage } from '@inertiajs/vue3'

export const sendErrorToBackend = async (errorDetails: any): Promise<void> => {
    try {
        await axios.post('/api/log-frontend-error', {
            details: errorDetails,
            url: usePage().props.currentUrl
        })
    } catch (error) {
        console.error('Failed to send error to backend:', error)
    }
}